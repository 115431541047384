/*-----------------------------------------------------------------------------------*/
/*	10.	Footer Styles
/*-----------------------------------------------------------------------------------*/

#footer {
    background: #eff0f2;
    font-size: 13px;
    color: #808080;
    clear: both;
    margin: 0 auto;
    box-shadow: 0 15px 15px -20px #b0b0b0 inset;
    -moz-box-shadow: 0 15px 15px -20px #b0b0b0 inset;
    -webkit-box-shadow: 0 15px 15px -20px #b0b0b0 inset;
}

#footer a {
    color: #464849;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
}

#footer a:hover {
    color: #333;
    text-decoration: none;
    border-bottom: 0;
}

#footer h4 {
    color: #fefefe;
    font-size: 18px;
    text-shadow: 1px 1px black;
    border-bottom: 1px dotted #555;
    padding: 15px 0;
}

#extended-footer {
    color: #747474;
    padding: 30px 0;
}

#extended-footer .inner {
    margin: 0 auto;
    max-width: 960px;
    display: block;
}

#extended-footer p {
    margin: 0;
    float: left;
}

#extended-footer a {
    color: #464849;
}

#extended-footer a:hover {
    color: #333;
}

#footer .testimonials {
    background: #333;
    text-align: center;
    border-bottom: 1px solid #323232;
}

#footer .thumb {
    width: auto;
    padding: auto;
    margin: auto;
}

#footer .testimonials .feedback {
    font-family: 'Raleway', arial, sans-serif;
    text-align: center;
    position: relative;
    margin: 0 auto 0 50px;
}

#footer .testimonials .feedback .thumb {
    margin-right: 20px;
}

#footer .testimonials .feedback img {
    float: left;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.4), 0 0 0 5px rgba(0, 0, 0, 0.1) inset;
}

#footer .testimonials .feedback .quote {
    padding: 15px 0;
    width: 850px;
}

#footer .testimonials .feedback blockquote {
    line-height: 18px;
    font-size: 16px;
    margin-left: 25px;
    font-family: 'Raleway', arial, sans-serif;
    color: #fff;
    font-style: italic;
    padding-top: 5px;
}

#footer .testimonials .feedback blockquote.no-img {
    font-size: 16px;
    margin: 0;
    padding: 0;
}

#footer .testimonials .feedback cite {
    font-size: 16px;
    color: #828282;
    margin-right: 5px;
}

#footer .testimonials .feedback .feedback-url {
    font-size: 16px;
    color: #3e7ec2;
    font-style: italic;
    margin-left: 5px;
}

#footer .testimonials .feedback .pagination {
    overflow: auto;
    padding: 5px 0 10px;
}

#footer .testimonials .feedback .pagination a {
    display: block;
    overflow: hidden;
    height: 40px;
    width: 40px;
}

#footer .testimonials .feedback .pagination .btn-next,
#footer .testimonials .feedback .pagination .btn-prev {
    background-repeat: no-repeat;
    background-image: url(assets/img/slide/bg_direction_nav.png);
    position: absolute;
    top: 30px;
    display: inline-block;
    text-indent: -999em;
    border: none;
}

#footer .testimonials .pagination .btn-next {
    float: right;
    right: 0;
    background-position: -24px 0;
}

#footer .testimonials .pagination .btn-prev {
    float: left;
    left: -50px;
}

#footer-navigation {
    float: right;
}

#footer-navigation ul li {
    float: left;
}

#footer-navigation ul li .separator {
    color: #ddd;
}

#footer-navigation ul li:first-child .separator {
    display: none;
}

#footer-navigation ul li a {
    margin: 0 15px;
    color: #888;
}

#footer-widgets .textwidget .read-more-link,
#nice_appointment input[type="submit"],
.button-bg {
    background-color: #a2d678;
    -webkit-box-shadow: 0 -4px rgba(0, 0, 0, 0.1) inset;
    -moz-box-shadow: 0 -4px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 -4px rgba(0, 0, 0, 0.1) inset;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.85);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    font-weight: bold;
    color: #fff;
}

.pre-footer-widgets div.last {
    border-right: 1px dotted #bbb;
}

.pre-footer-widgets .widget-section {
    border-left: 1px dotted #bbb;
    padding: 0 10px;
    margin-right: 0;
    width: 297px;
    float: left;
    margin-bottom: 30px;
    display: inline-block;
    overflow: hidden;
}

.pre-footer-widgets .widget-section .widgettitle {
    text-transform: uppercase;
    font-size: 18px;
    display: block;
    margin: 0 0 15px;
    border-top: 5px solid #444;
    padding-top: 15px;
}

/* Opening Hours*/
#footer-widgets .widget_nice_opening_hours .day {
    border-bottom: 1px solid #dfdfe2;
    -webkit-box-shadow: 0 1px 0 #fff;
    -moz-box-shadow: 0 1px 0 #fff;
    box-shadow: 0 1px 0 #fff;
}

#footer-widgets .widget_nice_opening_hours .day .name {
    color: #aaa;
}
/* FLICKR */
.widget_nice_flickr h3 span {
    color: #0061d8;
    padding: 0;
}

.widget_nice_flickr h3 span span {
    color: #ff1183;
    padding: 0;
}

.widget_nice_flickr .wrap {
    position: relative;
    padding: 0;
}

.widget_nice_flickr .flickr_badge_image {
    float: left;
    margin: 3px;
    background: #ddd;
    padding: 2px;
    height: 60px;
    width: 26.9%;
}

.widget_nice_flickr .flickr_badge_image:hover {
    background: #bbb;
    border-color: #eee;
}

#sidebar .widget_nice_flickr .flickr_badge_image {
    height: 80px;
}

.widget_nice_flickr .s div.flickr_badge_image:nth-of-type(3n+1) {
    margin-left: 0;
}

.widget_nice_flickr .s div.flickr_badge_image:nth-of-type(3n) {
    margin-right: 0;
}

.widget_nice_flickr a img {
    background: #4f4f4f;
    display: block;
    float: left;
    height: 100%;
    width: 100%;
    margin: 0;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

#footer-widgets .widget_nice_flickr h3 span {
    color: #0061d8;
}

#footer-widgets .widget_nice_flickr h3 span span {
    color: #ff1183;
}

#footer-widgets .widget_nice_flickr a img {
    background: #4f4f4f;
    height: 100%;
    width: 100%;
}
/* Calendar */
.widget #calendar_wrap {
    background: #efefef;
    padding: 1px;
}

.widget_calendar #wp-calendar {
    color: #555;
    width: 100%;
    text-align: center;
    border: none;
    background: #fff;
    margin: 0 auto;
}

.widget_calendar #wp-calendar caption,
.widget_calendar #wp-calendar td,
.widget_calendar #wp-calendar th {
    text-align: center;
    background: #fff;
}

.widget_calendar #wp-calendar caption {
    background: #f8f8f8;
    padding: 8px 0;
}

.widget_calendar #wp-calendar th {
    background: #f4f4f4;
    border-bottom: 1px solid #efefef;
    border-top: 1px solid #efefef;
    font-weight: bold;
}

.widget_calendar #wp-calendar tfoot td {
    background: #f4f4f4;
    border-bottom: 1px solid #ececec;
    border-top: 1px solid #ececec;
    padding: 8px 5px;
}

.widget_calendar #wp-calendar tr {
    border: none;
}

#footer-widgets .widget #calendar_wrap {
    border: 1px solid #ddd;
    padding: 0;
    background: transparent;
}

#footer-widgets .widget_calendar #wp-calendar,
#footer-widgets .widget_calendar #wp-calendar caption,
#footer-widgets .widget_calendar #wp-calendar td,
#footer-widgets .widget_calendar #wp-calendar th {
    background: transparent;
}

/* twitter */
.widget_nice_twitter ul li {
    background: #fff;
    padding: 8px 0;
}

.widget_nice_twitter ul li:hover {
    background: #fff;
}

.widget_nice_twitter p.tw-follow {
    margin-top: 10px;
    margin-bottom: 0;
    color: #888;
}

.widget_nice_twitter .one-tweet {
    font-size: 16px;
}

/* FEEDBACK */
.widget_nice_feedback .feedback {}

.widget_nice_feedback .feedback img {
    float: left;
}

.widget_nice_feedback .feedback .quote {
    padding: 0 0 10px;
    float: left;
}

.widget_nice_feedback .feedback blockquote {
    line-height: 18px;
    font-size: 14px;
    margin-left: 25px;
}

.widget_nice_feedback .feedback blockquote.no-img {
    font-size: 16px;
    margin: 0;
    padding: 0;
}

.widget_nice_feedback .feedback cite {
    float: right;
}

.widget_nice_feedback .feedback .feedback-url {
    display: block;
    text-align: right;
}

.widget_nice_feedback .feedback .pagination {
    overflow: auto;
    padding: 5px 0 10px;
    float: right;
    width: 40px;
}

.widget_nice_feedback .feedback .pagination a {
    display: block;
    width: 18px;
    height: 0;
    overflow: hidden;
    padding-top: 18px;
    border: none;
}

.widget_nice_feedback .feedback .pagination .btn-next,
.widget_nice_feedback .feedback .pagination .btn-prev {
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background-repeat: no-repeat;
    background-position: 5px center;
}

.widget_nice_feedback .feedback .pagination .btn-next {
    float: right;
    /* background-image: url(images/right-arrow.png); */
}

.widget_nice_feedback .feedback .pagination .btn-prev {
    float: left;
    /* background-image: url(images/left-arrow.png); */
}
/*-------------------------------------------------------------------------------------------*/
/* 13. Widgets */
/*-------------------------------------------------------------------------------------------*/

#sidebar .widget h3 {
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 20px;
}

.widget {
    clear: both;
    margin: 0 0 2em;
}

.widget ul {
    clear: both;
    list-style-position: inside;
    list-style-type: none;
    margin: 0;
}

.widget ul li {
    border-bottom: 1px solid #efefef;
    padding: 8px 8px 8px 0;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
}

.widget ul li a {
    color: #656565;
    border: none;
}

.widget ul li a:hover,
.widget ul li a:focus {
    border: none;
    color: #5a5a5a;
}

.widget ul li:last-child {
    border-bottom: none;
}

.widget select {
    font: 12px/20px 'Raleway', arial, sans-serif;
    color: #8a8a8a;
    width: 100%;
}

.widget .tagcloud a {
    background-color: #E0E4E7;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    color: #8A8A8A;
    display: block;
    float: left;
    font-size: 14px;
    margin: 5px 15px 5px 0;
    padding: 6px 10px;
    text-transform: capitalize;
}

.widget .textwidget img {
    /* max-width: 100%; */
    height: auto;
    margin: 10px 0 0 -0;
}

#footer-widgets {
    padding: 50px 0 25px;
    display: block;
    border-bottom: 1px solid #dfdfe2;
    -webkit-box-shadow: 0 1px 0 #fff;
    -moz-box-shadow: 0 1px 0 #fff;
    box-shadow: 0 1px 0 #fff;
}

#footer-widgets .widget {
    font-style: normal;
    color: #808080;
}

#footer-widgets .widget-wrap {
    max-width: 960px;
}

#footer-widgets .widget a {
    color: #464849;
}

#footer-widgets .widget li a {
    color: #464849;
}

#footer-widgets .widget li a:hover {
    color: #333;
    text-decoration: none;
}

#footer-widgets h3 {
    color: #3a3e44;
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 20px 0;
}

#footer-widgets .widget ul {
    padding: 0;
    background: none;
}

#footer-widgets .widget ul li {
    background: none;
    border-bottom: 1px solid #e7e7e7;
    -webkit-box-shadow: 0 1px 0 #fafafa;
    -moz-box-shadow: 0 1px 0 #fafafa;
    box-shadow: 0 1px 0 #fafafa;
}

#footer-widgets .widget ul li:first-child {
    padding-top: 0;
}

#footer-widgets .widget ul li:last-child {
    border-bottom: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

#footer-widgets .textwidget .read-more-link {
    background-color: #a2d678;
    color: #fff;
    display: block;
    font-size: 14px;
    font-weight: bold;
    margin-top: 5px;
    padding: 10px 0;
    width: 98px;
    text-align: center;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

#footer-widgets .textwidget .read-more-link {
    text-decoration: none;
}