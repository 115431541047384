/*-----------------------------------------------------------------------------------*/
/* 3. Navigation                                                                   */
/*-----------------------------------------------------------------------------------*/

.backtotop {
    bottom: -30px;
    border: none;
    color: #acb0b5;
    display: block;
    font-size: 20px;
    height: 32px;
    min-width: 29px;
    position: fixed;
    right: 17px;
    text-align: center;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    -o-transition: all 0.1s linear;
    transition: all 0.1s linear;
    z-index: 999;
}

.backtotop:hover {
    color: #8a8a8a;
    border: none;
}

.backtotop i.fa-chevron-up {
    display: inline-block;
    margin: 5px 1px 0 0;
}

#header #top #toggle-nav {
    display: none;
    height: 30px;
    position: absolute;
    right: 10px;
    text-align: center;
    line-height: 30px;
    font-size: 21px;
    top: 20px;
    width: 36px;
    background: #3e7ec2;
    text-shadow: none;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    color: #fff;
}

#header #top #toggle-nav:before,
#header #top #toggle-nav:after {
    content: '';
    left: 7px;
    position: absolute;
}

#header #top #toggle-nav:after {
    content: '';
    font-style: normal;
    line-height: 20px;
    text-shadow: none;
    top: 5px;
}

#navigation {
    font-size: 13px;
    padding: 20px 0;
    float: right;
    font-weight: 500;
}

.nav {
    z-index: 99;
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: 1;
}

#navigation .nav li a {
    position: relative;
    color: #fff;
    display: block;
    z-index: 100;
    padding: 5px 10px;
    line-height: 18px;
    text-decoration: none;
    text-transform: capitalize;
}

#navigation .nav li:hover a,
#navigation .nav li.current_page_item a,
#navigation .nav li.current-menu-item a #navigation .nav li.current-menu-ancestor a,
#navigation .nav li.current-menu-parent a,
#navigation .nav li.sfHover {
    background-color: #3e7ec2;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.has-slider #navigation .nav li.current_page_item a {
    background: none repeat scroll 0 0 rgba(0, 0, 0, .1);
    box-shadow: 0 0 9px 1px rgba(0, 0, 0, .11) inset;
    -webkit-box-shadow: 0 0 9px 1px rgba(0, 0, 0, .11) inset;
}

#navigation .nav li .down-arrow {
    background-color: rgba(0, 0, 0, .1);
    cursor: pointer;
    display: none;
    float: right;
    height: 28px;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 28px;
    color: #fff;
    text-align: center;
    line-height: 28px;
    z-index: 999;
}

#navigation .nav li a.sf-with-ul:after {
    font-family: 'FontAwesome';
    content: '\f107';
    display: inline-block;
    text-decoration: inherit;
    margin-left: 7px;
}

#navigation .nav li ul li a.sf-with-ul:after {
    margin-right: 5px;
    content: '\f105';
    float: right;
}

#navigation .nav li {
    float: left;
    width: auto;
    position: relative;
    margin-right: 10px;
}

#navigation .nav li a.sf-with-ul:hover {
    -webkit-border-bottom-right-radius: 0;
    -webkit-border-bottom-left-radius: 0;
    -moz-border-radius-bottomright: 0;
    -moz-border-radius-bottomleft: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

#navigation .nav li:last-child {
    margin-right: 0;
}

.nav li ul {
    display: none;
    position: absolute;
    left: -999em;
    width: 170px;
    z-index: 999;
    margin: 0;
    background-color: #3e7ec2;
    background-position: center top;
    font-size: 13px;
    -webkit-border-radius: 3px;
    -webkit-border-top-left-radius: 0;
    -moz-border-radius: 3px;
    -moz-border-radius-topleft: 0;
    border-radius: 3px;
    border-top-left-radius: 0;
}

#navigation .nav li ul li a {
    text-decoration: none;
}

.nav li ul li {
    border: none;
    display: block;
    background-image: none;
}

.nav li ul li:hover a {
    background-image: none;
}

#top #navigation .nav li ul li a {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    padding: 8px 10px;
    width: 170px;
    background-color: #3e7ec2;
    margin: 0;
    color: #fff;
    text-decoration: none;
}

#top #navigation .nav li ul li.current-menu-item a,
#top #navigation .nav li ul li:hover a,
.nav li ul ul li:hover a,
.nav li ul ul ul li:hover a {
    background: rgba(0, 0, 0, 0.1);
}

#top #navigation .nav li ul li:first-child a {
    -webkit-border-top-right-radius: 3px;
    -moz-border-radius-topright: 3px;
    border-top-right-radius: 3px;
}

#top #navigation .nav li ul li:last-child a {
    border: none;
    -webkit-border-bottom-right-radius: 3px;
    -webkit-border-bottom-left-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -moz-border-radius-bottomleft: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.nav li ul li a.sf-with-ul {
    padding: 5px 10px;
}

#top #navigation .nav li ul li a:hover,
.nav li ul li.current_page_item a,
.nav li ul li.current-menu-item a {
    color: #fff;
}

#top #navigation .nav li ul ul {
    margin: -34px 0 0 170px;
    padding: 0;
}

#top #navigation .nav li ul ul li a {}

#top #navigation .nav li ul ul li:hover a {}

.nav li:hover,
.nav li.hover {
    position: static;
}

.nav li:hover ul ul,
.nav li.sfhover ul ul,
.nav li:hover ul ul ul,
.nav li.sfhover ul ul ul,
.nav li:hover ul ul ul ul,
.nav li.sfhover ul ul ul ul {
    left: -999em;
}

.nav li:hover ul,
.nav li.sfhover ul,
.nav li li:hover ul,
.nav li li.sfhover ul,
.nav li li li:hover ul,
.nav li li li.sfhover ul,
.nav li li li li:hover ul,
.nav li li li li.sfhover ul {
    left: auto;
}

.nav .sf-sub-indicator {
    position: absolute;
    display: block;
    right: 0.4em;
    top: 0.9em;
    width: 10px;
    height: 10px;
    text-indent: -999em;
    overflow: hidden;
}

/* End Navigation */