@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
  display: inline-block;
  cursor: pointer;
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 4px;
}

.btn.btn-secondary {
  background-color: white;
  color: #000;
  border: 1px solid rgb(28, 55, 77);
}

.btn.btn-primary {
  background-color: rgb(28, 55, 77);
  color: #fff;
  border: 1px solid rgb(28, 55, 77);
}
