/*-----------------------------------------------------------------------------------*/
/*	2.	Header
/*-----------------------------------------------------------------------------------*/
#header {
    background-color: #528BCB;
    border-bottom: 7px solid rgba(0, 0, 0, 0.1);
}

.home-flexslider-wrapper {
    position: relative;
}

.has-slider #featured-wrap {
    background: #eff2f5;
    overflow: hidden;
    top: 0;
    width: 100%;
}

.has-slider #featured {
    margin: 0;
    max-width: 100%;
    overflow: hidden;
    position: static;
    z-index: 1;
}

.has-slider #header {
    background-color: transparent;
    border-bottom: medium none;
    padding: 15px 0;
    position: absolute;
    width: 100%;
    z-index: 1;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.35) 0%), to(rgba(0, 0, 0, 0) 100%));
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 100%);
    background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 100%);
    background-image: -ms-linear-gradient(top, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 100%);
    background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 100%);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 100%);
}

body.has-slider-menu #header {
    background-color: #2E2E2E;
    display: inline-block;
    position: relative;
}

#header #top {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    padding: 15px 0;
}

.has-slider #header #top {
    padding: 0;
    position: relative;
}

#header a {
    border: none;
}

#header #top #logo a .text-logo {
    color: #333;
    display: inline-block;
    font-size: 34px;
    font-weight: bold;
    line-height: 45px;
    padding: 0 0 5px 5px;
}

#header #top #logo #retina-logo {
    display: none;
}

/* Retina logo */
@media only screen and (-moz-min-device-pixel-ratio: 1.5),
only screen and (-o-min-device-pixel-ratio: 3/2),
only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min-device-pixel-ratio: 1.5),
only screen and (min-resolution: 144dpi) {
    #header #top #logo #retina-logo {
        display: block;
        height: 65px;
    }

    #header #top #logo #default-logo {
        display: none;
    }
}

/* END HEADER */